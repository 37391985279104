import { Box, Grid, Theme, Typography } from '@mui/material';
import { FC, Fragment, MouseEventHandler, useEffect } from 'react';
import { SxProps, alpha } from '@mui/system';

import { APPBAR_HEIGHT } from '../appTopBar/AppTopBar';
import LoadingView from '../LoadingView';
import { Masonry } from '@mui/lab';
import { MenuZone } from '@ward/library';
import { NavLink } from 'react-router-dom';
import { PageContainer } from '..';
import { Routes } from '../../../core/router';
import { keyframes } from '@emotion/react';
import themeVariable from '../../theme';

const slideDown = keyframes`
  from {top: -100vh;}
  to {top: 0;}
`;

const pageStyle: SxProps<Theme> = {
  position: 'fixed',
  zIndex: 900,
  background: theme => alpha(theme.palette.primary.main, .8),
  width: '100%',
  height: '100%',
  top: 0,
  paddingTop: `${APPBAR_HEIGHT}px`,
  backdropFilter: 'blur(16px)',
  overflow: 'auto',
  animation: `${slideDown} 400ms`,
};

const containerStyle: SxProps<Theme> = {
  alignContent: 'flex-start',
  maxWidth: theme => theme.breakpoints.values.md,
};

const categoryLabelStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  fontSize: 16,
  fontWeight: 700,
  marginTop: 6,
  marginBottom: 1.5,
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
};

const categoryStyle: SxProps<Theme> = {
  paddingRight: 8,
  [themeVariable.breakpoints.down('sm')]: {
    paddingRight: 0,
  },
  '&::last-child': {
    paddingRight: 0,
  },
};

const menuLabelStyle: SxProps<Theme> = {
  display: 'flex',
  position: 'relative',
  marginTop: '5px',
  '&::after': {
    content: "''",
    display: 'block',
    position: 'relative',
    background: theme => alpha(theme.palette.primary.contrastText, .24),
    flexGrow: 2,
    height: 1,
    top: 4,
    left: 0,
  },
};

const menuLabelTextStyle: SxProps<Theme> = {
  marginBottom: '10px',
  marginRight: '10px',
  fontSize: 10,
  fontWeight: 600,
  textTransform: 'uppercase',
};

const listStyle: SxProps<Theme> = {
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'wrap',
  a: {
    textDecoration: 'none',
    '&:active': {
      textDecoration: 'none !important',
    },
  },
};

const itemStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  background: theme => alpha(theme.palette.primary.contrastText, .17),
  paddingY: '10px',
  paddingX: 3,
  borderRadius: '25px',
  marginRight: 1.5,
  marginBottom: 1.5,
  textTransform: 'inherit',
  fontSize: 10,
  letterSpacing: '0.15em',
  fontWeight: 600,
  '&:hover': {
    background: theme => alpha(theme.palette.primary.contrastText, .3),
  },
};

export type LinksMenuCategory = {
  label: string,
  menus: Array<{
    label?: string | null,
    list: Array<{
      label: string,
      route?: string | null,
      link?: string | null,
    }>,
  }>,
};

type LinksMenuProps = {
  data: MenuZone[],
  close: MouseEventHandler<HTMLDivElement>,
  isLoading: any,
}

const handleClickLink = (event: any) => {
  event.stopPropagation();
};

const InternalRouteLink = ({ item, children }: any) => {
  if (item.url.includes('partenaires')) {
    return <NavLink to={Routes.PartnerUniversities}>{children}</NavLink>
  } else if (item.url.includes('absences')) {
    return <NavLink to={Routes.Absences}>{children}</NavLink>
  } else if (item.url.includes('agenda')) {
    return <NavLink to={Routes.Agenda}>{children}</NavLink>
  } else if (item.url.includes('notes')) {
    return <NavLink to={Routes.Notes}>{children}</NavLink>
  } else if (item.url.includes('suggestion')) {
    return <NavLink to={Routes.Suggestion}>{children}</NavLink>
  }else if (item.url.includes('around-the-world')) {
    return <NavLink to={Routes.AroundTheWorld}>{children}</NavLink>
  } else if (item.url.includes('shop')) {
    return <NavLink to={Routes.Shop}>{children}</NavLink>
  } else {
    return <a href={item.url} onClick={handleClickLink} target='_blank' rel="noreferrer">{children}</a>
  }
};

const InternalOrExternalLink = ({ item, children }: any) => {
  if (item.type === 'page') {
    return <NavLink to={`${Routes.Business}/${item.page}`}>{children}</NavLink>
  } else if (item.type === 'internal') {
    return <InternalRouteLink item={item}>{children}</InternalRouteLink>
  } else {
    return <a href={item.url} onClick={handleClickLink} target='_blank' rel="noreferrer">{children}</a>
  }
};

const LinksMenu: FC<LinksMenuProps> = ({ close, data, isLoading }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  });

  return (
    <Box sx={pageStyle} onClick={close}>
      <PageContainer>
        {isLoading
          ? <LoadingView />
          : (
            <Masonry columns={{xs: 2, sm: 3, md: 4}} spacing={1.5} sx={containerStyle}>
              {data.map((category: any, i: number) =>
                <Box key={i} sx={categoryStyle}>
                  <Typography sx={categoryLabelStyle}>{category.label}</Typography>
                  <Grid sx={listStyle}>
                    {category.links.map((item: any, l: number) =>
                      <InternalOrExternalLink key={l} item={item}>
                        <Typography variant="h2" sx={itemStyle}>{item.label}</Typography>
                      </InternalOrExternalLink>
                    )}
                  </Grid>
                  {category.sections.map((menu: any, k: number) =>
                    <Fragment key={k}>
                      {menu.label &&
                        <Grid sx={menuLabelStyle}>
                          <Typography sx={menuLabelTextStyle}>{menu.label}</Typography>
                        </Grid>
                      }
                      <Grid sx={listStyle}>
                        {menu.links.map((item: any, l: number) =>
                          <InternalOrExternalLink key={l} item={item}>
                            <Typography variant="h2" sx={itemStyle}>{item.label}</Typography>
                          </InternalOrExternalLink>
                        )}
                      </Grid>
                    </Fragment>
                  )}
                </Box>
              )}
            </Masonry>
          )
        }
      </PageContainer>
    </Box>
  );
};

export default LinksMenu;
